export const TARIFF_TYPE: { [id: string]: string } = {
  SR: 'Single rate',
  SRCL: 'Single rate',
  BLK: 'Block rates', // Not block rates
  // QUOTA: 'Block rates', // Not block rates
  TOU: 'Time of use',
  TOUCL: 'Time of use',
  FLEX: 'Time of use', // Victoria
  FLEXCL: 'Time of use', // Victoria
  OTH: 'Other',
};

export const CONTRACT_TERM: { [id: string]: string } = {
  N: 'No contract',
  '1': '1 year',
  '2': '2 years',
  '3': '3 years',
  '4': '4 years',
  '5': '5 years',
  E: 'Ongoing',
  O: 'Other',
};

export enum PlanFeatureFilters {
  ausBasedRequired = 'ausBasedRequired',
  ausOwnedRequired = 'ausOwnedRequired',
  discountRequired = 'discountRequired',
  fixedPriceRequired = 'fixedPriceRequired',
  greenPowerRequired = 'greenPowerRequired',
  guaranteedDiscountRequired = 'guaranteedDiscountRequired',
  includeEvPlans = 'includeEvPlans',
  noExitFeesRequired = 'noExitFeesRequired',
  onlyBestOfferPerRetailer = 'onlyBestOfferPerRetailer',
}

export enum StepUrl {
  URL_POSTCODE_HOUSEHOLD = '/compare/',
  URL_POSTCODE_BUSINESS = '/business/',
  URL_DISTRIBUTOR_CHOICE = '/distributor/',
  URL_BILL_TYPE = '/bill-type/',
  URL_YOUR_HOUSEHOLD = '/household/',
  URL_ABOUT_YOUR_BILL = '/about-your-bill/',
  URL_CONTROLLED_LOAD = '/controlled-load/',
  URL_CONTROLLED_LOAD_USAGE = '/controlled-load-details/',
  URL_SOLAR_INSTALLED = '/solar/',
  URL_SOLAR_INPUT = '/solar-input/',
  URL_SOLAR_SYSTEM_DETAILS = '/solar-details/',
  URL_BLOCK_RATES_TARIFF = '/block-rates/',
  URL_SINGLE_RATE_TARIFF = '/single-rate/',
  URL_PEAK_OFF_PEAK_TARIFF = '/peak-off-peak/',
  URL_RETAILER_CHOICE = '/retailer/',
  URL_RESULTS = '/results/',
  URL_PLAN_INFO = '/plan-info/',
  URL_ALMOST_THERE = '/onboarding/',
  URL_LIFE_SAVING = '/life-saving/',
}

export const MeterType: { [id: string]: string } = {
  'Type 4': 'Smart meter',
  'Type 4a': 'Smart meter - no comms',
  'Type 5': 'Manually read smart meter',
  'Type 6': 'Basic meter',
  // 'Type 0': 'null',
};

export const BillFrequencyPeriod: { [id: string]: string } = {
  D: 'day',
  M: 'month',
  Q: 'quarter',
  Y: 'year',
};
